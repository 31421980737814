const dialog = document.getElementById('dialog')
const spinner = new Spin.Spinner({
    lines: 13, // The number of lines to draw
    length: 18, // The length of each line
    width: 8, // The line thickness
    radius: 20, // The radius of the inner circle
    scale: 1, // Scales overall size of the spinner
    corners: 1, // Corner roundness (0..1)
    speed: 1, // Rounds per second
    rotate: 0, // The rotation offset
    animation: 'spinner-line-fade-quick', // The CSS animation name for the lines
    direction: 1, // 1: clockwise, -1: counterclockwise
    color: '#ffffff', // CSS color or array of colors
    fadeColor: 'transparent', // CSS color or array of colors
    top: '50%', // Top position relative to parent
    left: '50%', // Left position relative to parent
    shadow: '0 0 1px transparent', // Box-shadow for the lines
    zIndex: 2000000000, // The z-index (defaults to 2e9)
    className: 'spinner', // The CSS class to assign to the spinner
    position: 'absolute' // Element positioning
})
const body = document.body.querySelector('.merge-page')
const spinnerStart = () => {
    spinner.spin(body)
    body.style['pointer-events'] = 'none'
}
const spinnerStop = () => {
    spinner.stop()
    body.style['pointer-events'] = ''
}
const toast = (message) => {
    Toastify({
        text: message,
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top', // `top` or `bottom`
        position: 'center', // `left`, `center` or `right`
        stopOnFocus: true, // Prevents dismissing of toast on hover
        style: {
            // background: 'linear-gradient(to right, #00b09b, #96c93d)'
            background: '#999'
            // 'box-shadow': '0 2px 8px rgba(0, 0, 0, 0.15)'
        },
        onClick: function () {} // Callback after click
    }).showToast()
}
let heroDic = {}
let curWallet = {}
window.addEventListener('iota-ready', () => {
    window.onConnect = async () => {
        const res = await iota.request({
            method: 'iota_connect',
            params: {
                // expires: 3000000
            }
        })
        curWallet = res
    }
    window.onConnect().then(() => {
        if (curWallet.nodeId != 101 && curWallet.nodeId != 102) {
            iota.request({
                method: 'iota_changeAccount',
                params: {
                    network: 'shimmer'
                }
            })
        }
    })
    window.handleSelect = async (type) => {
        if (window.isLoading) {
            return
        }
        spinnerStart()
        window.isLoading = true
        try {
            await window.onConnect()
            let heroName = ''
            for (const i in heroDic) {
                if (heroDic[i]) {
                    heroName = heroDic[i].name
                }
            }
            const params = {
                heroLevel: type
            }
            if (heroName) {
                params.name = heroName
            }
            const res = await iota.request({
                method: 'iota_merge_nft',
                params: params
            })
            heroDic[type] = res[0]
            spinnerStop()
            showHero(type)
            window.isLoading = false
        } catch (error) {
            console.log(error)
            if (error?.status) {
                toast(error.msg)
            }
            spinnerStop()
            window.isLoading = false
        }
    }
})
const mergeBtnEl = document.getElementById('merge-btn')
function showHero(type) {
    const info = heroDic[type]
    console.log(info)
    const itemEl = document.getElementById(`item${type}`)
    const noImg = itemEl.querySelector('.no-img')
    const hasImg = itemEl.querySelector('.has-img')
    const arEl = itemEl.querySelector('.ar')
    if (info) {
        noImg.classList.add('hidden')
        hasImg.classList.remove('hidden')
        // hasImg.querySelector('img').src = info.media || info.uri
        const url = curWallet.nodeId == 102 ? `https://api.iotaichi.com` : `https://api.test.iotaichi.com`
        hasImg.querySelector('img').src = `${url}/uploadFile/legend/${info.name}-${info.heroLevel}.png`
        showAr(arEl, info)
    } else {
        noImg.classList.remove('hidden')
        hasImg.classList.add('hidden')
        arEl.innerHTML = ''
        showAr(arEl, {})
    }
    if (checkMergeBtn()) {
        mergeBtnEl.classList.remove('disable')
    } else {
        mergeBtnEl.classList.add('disable')
    }
}
const showAr = (target, info) => {
    const arInfo = (info?.attributes || []).find((e) => e.trait_type == 'airdroprewardlevel')
    if (arInfo) {
        target.innerHTML = `AR: ${arInfo?.value}`
    }
}
function checkMergeBtn() {
    return heroDic.Hero && heroDic.Elite && heroDic.Epic
}
function handleContinue() {
    handleReset()
    document.getElementById('select-con').classList.remove('hidden')
    document.getElementById('succ-con').classList.add('hidden')
    document.getElementById('select-btns').classList.remove('hidden')
    document.getElementById('succ-btns').classList.add('hidden')
}
function handleMerge() {
    if (checkMergeBtn()) {
        dialog.classList.remove('hidden')
    }
}
function handleRemove(type, e) {
    e.stopPropagation()
    delete heroDic[type]
    showHero(type)
}
function handleReset() {
    heroDic = {}
    showHero('Hero')
    showHero('Elite')
    showHero('Epic')
}

function handleCancel() {
    dialog.classList.add('hidden')
}
const request = async (api, params) => {
    const url = curWallet.nodeId == 102 ? `https://api.iotaichi.com/api?${api}` : `https://api.test.iotaichi.com/api?${api}`
    const res = await axios.get(url, {
        params
    })
    if (res.status == 200 && res.data.status == 0) {
        return res.data
    }
    toast(res?.data?.message)
    return null
}
const getApi = () => {
    return curWallet.nodeId == 102 ? 'https://mainnet.shimmer.node.tanglepay.com' : 'https://test.shimmer.node.tanglepay.com'
}
const getTxId = async (blockId) => {
    try {
        const api = getApi()
        const url = `${api}/api/core/v2/blocks/${blockId}`
        const blockRes = await axios.get(url)
        const transactionId = blockRes?.data?.payload?.essence?.inputs?.[0]?.transactionId
        const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
        await sleep(4000)
        const txRes = await axios.get(`${api}/api/core/v2/outputs/${transactionId}0100`)
        return txRes?.data?.metadata?.transactionIdSpent
    } catch (error) {
        console.log(error)
        return null
    }
}
const getNftInfo = async (txId) => {
    return new Promise(async (resolve, reject) => {
        const requestNftInfo = async () => {
            const api = getApi()
            try {
                const res = await axios.get(`${api}/api/core/v2/outputs/${txId}`)
                console.log(res)
                const immutableFeatures = res?.data?.output?.immutableFeatures
                let info = (immutableFeatures || []).find((d) => {
                    return d.type == 2
                })
                if (info && info.data) {
                    try {
                        info = Converter.hexToUtf8(info.data.replace(/^0x/, ''))
                        info = JSON.parse(info)
                    } catch (error) {
                        console.log(error)
                    }
                }
                resolve(info)
            } catch (error) {
                setTimeout(() => {
                    requestNftInfo()
                }, 2000)
            }
        }
        requestNftInfo()
    })
}
function showSuccInfo(info) {
    document.getElementById('select-con').classList.add('hidden')
    document.getElementById('succ-con').classList.remove('hidden')
    document.getElementById('select-btns').classList.add('hidden')
    document.getElementById('succ-btns').classList.remove('hidden')
    showLegendary(info)
}
function showLegendary(info) {
    console.log(info)
    const el = document.getElementById('itemLegendary')
    const imgEl = el.querySelector('.has-img img')
    const arEl = el.querySelector('.ar')
    showAr(arEl, info)
    let url = `https://ipfs.io/ipfs/${info.uri.replace('ipfs:/', '')}`
    if (info.legendImageUrl) {
        url = info.legendImageUrl
    }
    imgEl.src = url
    const attrEl = document.getElementById('attr-con')
    const getAttr = (key) => {
        return (info?.attributes || []).find((e) => e.trait_type == key)?.value || '--'
    }
    attrEl.innerHTML = `
    <div class="attr"><span class="label">Agility</span><span class="value">${getAttr('agility')}</span></div>
    <div class="attr"><span class="label">Defense</span><span class="value">${getAttr('defense')}</span></div>
    <div class="attr"><span class="label">Magic</span><span class="value">${getAttr('magic')}</span></div>
    <div class="attr"><span class="label">Physical</span><span class="value">${getAttr('physical')}</span></div>
    <div class="attr"><span class="label">Stamina</span><span class="value">${getAttr('stamina')}</span></div>
    `
}
function showWaitStatus() {
    const spinnerEl = document.querySelector('.merge-page .spinner')
    if (spinnerEl) {
        const waitEl = document.createElement('div')
        waitEl.innerText = 'We are currently in the process of summoning your Legendary NFT. This process may take up to a minute usually.'
        waitEl.id = 'waitStatus'
        spinnerEl.appendChild(waitEl)
    }
}
function hideWaitStatus() {
    const spinnerEl = document.querySelector('.merge-page .spinner')
    const waitEl = document.getElementById('waitStatus')
    if (spinnerEl && waitEl) {
        spinnerEl.removeChild(waitEl)
    }
}
async function handleOk() {
    dialog.classList.add('hidden')
    spinnerStart()
    try {
        const params = {
            address: curWallet.address
        }

        if (!curWallet.sessionInfo) {
            const res = await request('method=legendSession.getPendingSession', {
                ...params
            })
            console.log('getPendingSession====', res)
            if (res?.data?.id) {
                curWallet.sessionInfo = res.data
            } else {
                const creaateRes = await request('method=legendSession.create', {
                    ...params
                })
                console.log('legendSession.create=====', creaateRes)
                curWallet.sessionInfo = creaateRes.data
            }
        }
        const preRes = await request('method=legendSession.preUploadNfts', {
            ...params,
            id: curWallet.sessionInfo?.id
        })
        console.log('preRes======', preRes)
        if (!preRes) {
            spinnerStop()
            return toast('Server is busy, please try again later.')
        }
        const nftIds = Object.values(heroDic).map((e) => e.nftId)
        const res = await iota.request({
            method: 'iota_sendTransaction',
            timeout: 60000000,
            params: {
                to: curWallet.sessionInfo?.collectionAddress,
                value: 1,
                nftId: nftIds.join(',')
            }
        })
        console.log('sendTransactionRes====', res)
        toast('Merging in progress, please wait.')
        showWaitStatus()
        const txId = await getTxId(res?.blockId)
        console.log('txId======', txId)
        if (txId) {
            await request('method=legendSession.uploadNfts', {
                ...params,
                id: curWallet.sessionInfo?.id,
                txId
            })
        }
        const { nftTxId, imgUrl } = await getMergeDetail()
        const info = await getNftInfo(nftTxId)
        console.log('NFTINFO=======', info)
        showSuccInfo({
            ...info,
            legendImageUrl: imgUrl
        })
        hideWaitStatus()
        spinnerStop()
    } catch (error) {
        if (error?.msg == 'cancel') {
            curWallet.sessionInfo = null
        } else {
            console.log('error=====', error)
            toast(String(error))
        }

        spinnerStop()
    }
}

async function getMergeDetail() {
    return new Promise(async (resolve, reject) => {
        const requestStatus = async () => {
            const res = await request('method=legendSession.getDetail', {
                address: curWallet.address,
                id: curWallet.sessionInfo?.id
            })
            console.log(res)
            const status = res.data.status
            if (!(status == 50 || status == 10)) {
                setTimeout(() => {
                    requestStatus()
                }, 3000)
            } else if (status == 50) {
                resolve({
                    nftTxId: res.data.sendNftTxId,
                    imgUrl: res.data.legendImageUrl
                })
            }
        }
        requestStatus()
    })
}

window.showNav = function () {
    var el = document.getElementById('nav-panel')
    if (el) {
        el.classList.toggle('nav-show')
    }
}

var nftList = []
